import React from 'react';
import styled from "styled-components";
import VideoItem from './videoItem';
import Section from '../_global/layout/Section';
import Text from '../_global/editorial/Text';
import Spacer from '../_global/layout/Spacer';
import Block from '../_global/editorial/Block';

const Comp = styled.div`
    margin: 0 auto;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    @media (max-width: ${({ theme }) => theme.breakPoints.m}) {
        grid-template-columns: repeat(2, 1fr);
    }

@media (max-width: ${({ theme }) => theme.breakPoints.s}) {
    grid-template-columns: repeat(1, 1fr);
}
`

const Videos = (props) => {
    const { videos, category } = props

    const videoItems = videos ? videos.map((item, index) => {
        return <VideoItem data={item} index={index} key={index} />
    }) : null

    return (
        <Section m="medium">
            <Text s="h2" c="main01" style={{ textTransform: "capitalize", textAlign: 'center' }}>
                {category.title}
            </Text>
            <Spacer s="medium" />
            <Block data={category.text} />
            <Spacer s="medium" />

            <Comp>
                {videoItems}
            </Comp>
        </Section>
    );
};

export default Videos;