import React from 'react';
import styled from "styled-components";
import { graphql } from "gatsby";
import Text from '../_global/editorial/Text';
import Spacer from '../_global/layout/Spacer';

const Comp = styled.div`
    display:flex ;
    flex-direction: column;
    flex: 1;
`

const Iframe = styled.iframe`
    aspect-ratio: 1.777777777777778;
    width: 100%;
`

const VideoItem = (props) => {
    const { data } = props
    return (
        <Comp>
            <Iframe src={data.source} allowFullScreen></Iframe>
            <Spacer s="small" />

            <Text s="medium" w="bold">
                {data.title}
            </Text>

            <Text s="medium">
                {data.description}
            </Text>
        </Comp>
    );
};

export default VideoItem;

export const query = graphql`
    fragment VideoFragment on SanityVideo {
        categories {
            _id
            title {
                _type
                fr
            }
        }
        description {
          _type
          fr
        }
        title {
          _type
          fr
        }
        source
    }
`;