import React, { useState } from "react";
import { graphql } from "gatsby";
import PageHeader from "../components/_global/layout/PageHeader";
import Section from "../components/_global/layout/Section";
import Layout from "../components/_global/layout/Layout";
import localize from "../components/_global/localization/localize";
import Block from "../components/_global/editorial/Block";
import Spacer from "../components/_global/layout/Spacer";
import Videos from "../components/10_video/videos";
import VideoTags from "../components/10_video/VideoTags";
import { removeDuplicateObjects } from "../utils/utils";

const Discover = ({ data, ...props }) => {
    const { page } = data;
    const videoItems = data.allSanityVideo.nodes;
    const allCategories = data.allSanityVideoCategory.nodes;
    let cats = videoItems.map(item => {
        return item.categories
    });
    cats = cats.flat();
    const categories = removeDuplicateObjects(cats, "_id")

    const [category, setCategory] = useState(-1);

    return (
        <Layout title={page.common.title}>
            <PageHeader title={page.common.title} />

            <Section m="medium">
                <Block data={page.text} />
                <Spacer s="large" />
            </Section>

            <VideoTags categories={categories} category={category} setCategory={setCategory} allCategories={allCategories} />

            <Spacer s="medium" />
            <Videos
                videos={category !== -1 ? videoItems.filter(i => i.categories.some(cat => cat._id === category)) : videoItems}
                category={category !== -1 ? allCategories.find(i => i._id === category) : allCategories.find(i => i.all)}
            />
            <Spacer s="large" />
            <Spacer s="large" />
        </Layout>
    );
};

export default localize(Discover);

export const query = graphql`
    {
        page: sanityDiscover {
            common {
                link
                title {
                    _type
                    fr
                }
            }
            text {
                ...CompleteBlockFragment
            }
        }
        allSanityVideo(sort: {fields: title___fr, order: ASC}) {
            nodes {
                ...VideoFragment
            }
        }
        allSanityVideoCategory(sort: {fields: title___fr, order: ASC}) {
            nodes {
                ...VideoCategoryFragment
            }
        }
    }
`;